
import { Component, Vue, Prop } from 'vue-property-decorator'
import { mdiAccount, mdiAt, mdiChairRolling } from '@mdi/js'

@Component
export default class ContactsTable extends Vue {
  @Prop({ required: true, type: Array }) contacts!: yakkyo.IShopMonitorContact[]

  svgAccount = mdiAccount
  svgChairRolling = mdiChairRolling
  svgAt = mdiAt
}
